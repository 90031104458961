<template>
  <div class="content-wrapper overflow-visible">
    <patient-info :patient-data="patientData" :loading="loading.patientInfo" :use-history-data="true" :reg-id="$route.query.regId" />

    <div class="content">
      <validation-observer ref="VForm">
        <b-form @submit.prevent="doSubmit()">
          <div class="card">
            <div class="card-header bg-white">
              <h5 class="card-title font-weight-semibold">Form Checklist Keselamatan</h5>
            </div>
            
            <!-- -->
            <div class="card-body">
              <div class="row">
                <div class="col-md-12">
                  <div class="wrap_width_table">
                    <div class="table-responsive">
                      <table class="table  table-sm  table-bordered">
                        <thead>
                          <tr>
                            <th>Sign In</th>
                          </tr>
                          <tr>
                            <th class="table-info">Sebelum induksi anesthesi <br> (dengan perawat dan dokter anesthesi)
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="form-group col-md-6 col-lg-4">
                                    <label for="kenyamananNyeri">Tanggal <span class="text-danger">*</span></label>
                                    <div class="input-group">
                                      <datepicker input-class="form-control transparent h-38" placeholder="Tanggal"
                                          @input="inputData('date')" id="date"
                                          class="my-datepicker" calendar-class="my-datepicker_calendar"
                                          v-model="row.arancka_date">
                                      </datepicker>
                                      <vue-timepicker manual-input @input="inputData('jam')" id="jam" format="HH:mm" placeholder="Jam" input-class="form-control h-38" v-model="row.arancka_jam">
                                      </vue-timepicker>
                                      <div class="input-group-append calendar-group">
                                          <span class="input-group-text" id="basic-addon2"><i
                                              class="icon-calendar"></i></span>
                                      </div>
                                    </div>

                                                    
                                    <span class="text-warning d-none" id="date-typing">User Lain Sedang Mengisi ...</span>         
                                                    
                                    <span class="text-warning d-none" id="jam-typing">User Lain Sedang Mengisi ...</span>         
                                    <VValidate 
                                        :name="'Tanggal Sign In'" 
                                        v-model="row.arancka_date" 
                                        :rules="{required : 1}"
                                        class="d-block"
                                    />
                                    <VValidate :name="'Jam Sign In'" v-model="row.arancka_jam"
                                        :rules="{required: 1}" />
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                            
                              <div>
                                Apakah pasien telah memberikan konfirmasi kebenaran indentitasnya,<br>
                                lokasi operasi, prosedurnya dan telah memberikan persetujuan dalam lembar informed
                                concent?
                                <span class="text-danger"> *</span>
                              </div>
                              <b-form-checkbox
                                v-model="row.arancka_konfirmasi_identitas"
                                name="arancka_konfirmasi_identitas"
                                value="Y"
                                :unchecked-value="null"
                                @change="inputData('konfirmasi_identitas')" id="konfirmasi_identitas"
                              >
                                Ya
                              </b-form-checkbox>
                        
                              <span class="text-warning d-none" id="konfirmasi_identitas-typing">User Lain Sedang Mengisi ...</span>    
                              
                              <VValidate 
                                  name="Konfirmasi Kebenaran identitas" 
                                  v-model="row.arancka_konfirmasi_identitas" 
                                  :rules="{required:1}"
                              />
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <div>Apakah lokasi operasi sudah diberi tanda/marking?
                              <span class="text-danger"> *</span>                              
                              </div>
                              <b-form-radio-group
                                @change="inputData('lokasi_marking')" id="lokasi_marking"
                                :options="Config.mr.yesNoOptV2"
                                v-model="row.arancka_lokasi_marking"
                              />
                              <span class="text-warning d-none" id="lokasi_marking-typing">User Lain Sedang Mengisi ...</span>
                              
                              <VValidate 
                                  name="Tanda Marking" 
                                  v-model="row.arancka_lokasi_marking" 
                                  :rules="{required:1}"
                              />
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <div>Apakah mesin dan obat anestesi telah dicek dan lengkap? <strong class="text-danger">*</strong></div>
                              <b-form-checkbox
                                @change="inputData('mesin_dicek')" id="mesin_dicek"
                                v-model="row.arancka_mesin_dicek"
                                name="arancka_mesin_dicek"
                                value="Y"
                                :unchecked-value="null"
                              >
                                Ya
                              </b-form-checkbox>
                              
                              <span class="text-warning d-none" id="mesin_dicek-typing">User Lain Sedang Mengisi ...</span>
                              
                              <VValidate 
                                  name="Mesin dan obat anestesi" 
                                  v-model="row.arancka_mesin_dicek" 
                                  :rules="{required:1}"
                              />
                            </td>

                          </tr>
                          <tr>
                            <td>
                              <div>Apakah pulse oximeter sudah terpasang dan berfungsi? <strong class="text-danger">*</strong>
                              </div>
                              <b-form-checkbox
                                @change="inputData('pulse_oximeter')" id="pulse_oximeter"
                                v-model="row.arancka_pulse_oximeter"
                                name="arancka_pulse_oximeter"
                                value="Y"
                                :unchecked-value="null"
                              >
                                Ya
                              </b-form-checkbox>
                              
                              <span class="text-warning d-none" id="pulse_oximeter-typing">User Lain Sedang Mengisi ...</span>

                              <VValidate 
                                  name="Pulse oximeter" 
                                  v-model="row.arancka_pulse_oximeter" 
                                  :rules="{required:1}"
                              />
                            </td>

                          </tr>

                          <tr>
                            <td>
                              <div>
                                <h6 class="font-weight-semibold text-danger">Apakah pasien memiliki </h6>
                              </div>
                              <div class="form-group">
                                <label class="font-weight-semibold">Riwayat alergi yang diketahui <strong class="text-danger">*</strong></label>
                                <b-form-radio-group
                                  @input="inputData('riwayat_alergi')" id="riwayat_alergi"
                                  :options="Config.mr.yesNoOptV2"
                                  v-model="row.arancka_riwayat_alergi"
                                />
                                  
                                <span class="text-warning d-none" id="riwayat_alergi-typing">User Lain Sedang Mengisi ...</span>

                                <VValidate 
                                  name="Riwayat alergi yang diketahui" 
                                  v-model="row.arancka_riwayat_alergi" 
                                  :rules="{required:1}"
                                />
                              </div>

                              <div class="form-group">
                                <label class="font-weight-semibold">Resiko kesulitan pada jalan nafas atau resiko
                                  aspirasi? <strong class="text-danger">*</strong></label>
                                  <b-form-radio-group
                                  @change="inputData('resiko_kesulitan')" id="resiko_kesulitan"
                                    :options="Config.mr.yesNoOptV2"
                                    v-model="row.arancka_resiko_kesulitan"
                                  />
                                    
                                  <span class="text-warning d-none" id="resiko_kesulitan-typing">User Lain Sedang Mengisi ...</span>

                                  <VValidate 
                                    name="Resiko kesulitan pada jalan nafas atau resiko
                                  aspirasi" 
                                    v-model="row.arancka_resiko_kesulitan" 
                                    :rules="{required:1}"
                                  />
                              </div>

                              <div class="form-group">
                                <label class="font-weight-semibold">Resiko kehilangan darah > 500 ml (7ml/kg BB pada
                                  anak) <strong class="text-danger">*</strong></label>
                                  <b-form-radio-group
                                    @input="inputData('kehilangan_darah')" id="kehilangan_darah"
                                    :options="Config.mr.yesNoOptV2"
                                    v-model="row.arancka_kehilangan_darah"
                                  />
                                    
                                  <span class="text-warning d-none" id="kehilangan_darah-typing">User Lain Sedang Mengisi ...</span>

                                  <VValidate 
                                    name="Resiko kehilangan darah" 
                                    v-model="row.arancka_kehilangan_darah" 
                                    :rules="{required:1}"
                                  />
                              </div>
                            </td>

                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                </div>
              </div>
            </div>
            <div class="card-footer">
              <div class="text-right">
                <button type="button" @click="updateTime('arano_last_sign_in','Sign In')" class="btn btn-primary">Simpan <i class="icon-paperplane ml-2"></i></button>
                <br/><small v-if="row.arano_last_sign_in">Last Update : {{row.arano_last_sign_in | moment("DD MMMM YYYY, HH:mm")}}</small>
              </div>
            </div>
            <!-- -->

            <!-- -->
            <div class="card-body">
              <div class="row">
                <div class="col-md-12">
                  <div class="wrap_width_table">
                    <div class="table-responsive">
                      <table class="table  table-sm  table-bordered">
                        <thead>
                          <tr>
                            <th>Time Out</th>
                          </tr>
                          <tr>
                            <th class="table-info">Sebelum insisi kulit <br>(dengan perawat, dokter anesthesi dan dokter
                              bedah )</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="form-group col-md-6 col-lg-4">
                                    <label for="kenyamananNyeri">Tanggal <span class="text-danger">*</span></label>
                                    <div class="input-group">
                                    <datepicker @input="inputData('to_date')" id="to_date" input-class="form-control transparent h-38" placeholder="Tanggal"
                                        class="my-datepicker" calendar-class="my-datepicker_calendar"
                                        v-model="row.arancka_to_date">
                                    </datepicker>
                                    <vue-timepicker manual-input @input="inputData('to_jam')" id="to_jam" format="HH:mm" placeholder="Jam" input-class="form-control h-38" v-model="row.arancka_to_jam">
                                    </vue-timepicker>
                                    <div class="input-group-append calendar-group">
                                        <span class="input-group-text" id="basic-addon2"><i
                                            class="icon-calendar"></i></span>
                                    </div>
                                    </div>
                                      
                                    <span class="text-warning d-none" id="to_date-typing">User Lain Sedang Mengisi ...</span>
                                    
                                    <span class="text-warning d-none" id="to_jam-typing">User Lain Sedang Mengisi ...</span>

                                    <VValidate 
                                        :name="'Tanggal Time Out'" 
                                        v-model="row.arancka_to_date" 
                                        :rules="{required : 1}"
                                        class="d-block"
                                    />
                                    <VValidate :name="'Jam Time Out'" v-model="row.arancka_to_jam"
                                        :rules="{required: 1}" />
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <b-form-checkbox
                                @change="inputData('seluruh_anggota_menyebutkan_nama')" id="seluruh_anggota_menyebutkan_nama"
                                v-model="row.arancka_seluruh_anggota_menyebutkan_nama"
                                name="arancka_seluruh_anggota_menyebutkan_nama"
                                value="Y"
                                :unchecked-value="null"
                              >
                                Seluruh anggota tim telah menyebutkan nama dan peran masing-masing
                              </b-form-checkbox>
                              
                              <span class="text-warning d-none" id="seluruh_anggota_menyebutkan_nama-typing">User Lain Sedang Mengisi ...</span>

                              <VValidate name="Seluruh anggota tim telah menyebutkan nama dan peran masing-masing" v-model="row.arancka_seluruh_anggota_menyebutkan_nama"
                              :rules="{required:1}" />
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <b-form-checkbox
                                @change="inputData('pastikan_nama_pasien')" 
                                id="pastikan_nama_pasien"
                                v-model="row.arancka_pastikan_nama_pasien"
                                name="arancka_pastikan_nama_pasien"
                                value="Y"
                                :unchecked-value="null"
                              >
                                Pastikan nama pasien, nama prosedur dan dimana insisi akan dilakukan
                              </b-form-checkbox>

                              <span class="text-warning d-none" id="pastikan_nama_pasien-typing">User Lain Sedang Mengisi ...</span>
                              
                              <VValidate name="Pastikan nama pasien, nama prosedur dan dimana insisi akan dilakukan" v-model="row.arancka_pastikan_nama_pasien"
                              :rules="{required:1}" />
                            </td>
                          </tr>

                          <tr>
                            <td class>
                              <div>Antibiotik profilaksis telah diberikan dalam 60 menit?</div>
                              <div class="d-flex">
                                <b-form-radio-group class="mr-2"
                                  @input="inputData('antibiotik')" 
                                  id="antibiotik"
                                  :options="Config.mr.yesNoOptV2"
                                  v-model="row.arancka_antibiotik"
                                />
                                <div v-if="row.arancka_antibiotik == 'Y'">
                                  <b-form-input
                                  @input="inputData('antibiotik_diterapkan')" 
                                  id="antibiotik_diterapkan"
                                  v-model="row.arancka_antibiotik_diterapkan" type="text"
                                  class="form-control" placeholder="Oleh"/>
                    
                                  <VValidate name="Diberikan Oleh" v-model="row.arancka_antibiotik_diterapkan"
                                      :rules="{required: 1}" />
                                </div>
                              </div>
                              
                              <span class="text-warning d-none" id="antibiotik-typing">User Lain Sedang Mengisi ...</span>
                              
                              <span class="text-warning d-none" id="antibiotik_diterapkan-typing">User Lain Sedang Mengisi ...</span>
                              
                              <VValidate name="Antibiotik profilaksis telah diberikan dalam 60 menit" v-model="row.arancka_antibiotik"
                              :rules="{required:1}" />
                            </td>
                          </tr>

                          <tr>

                            <td>
                              <div>
                                <h6 class="font-weight-semibold text-danger">ANTISIPASI KEJADIAN KRITIS</h6>
                              </div>
                              <div class="form-group">
                                <label class="font-weight-semibold">Operator</label>
                                <div class="row">
                                  <div class="col-md-4">
                                    <b-form-checkbox
                                        @change="inputData('hal_kritis')" id="hal_kritis"
                                        v-model="row.arancka_hal_kritis"
                                        name="arancka_hal_kritis"
                                        value="Y"
                                        unchecked-value="N"
                                    >
                                      Hal kritis atau langkah tak terduga apakah yang mungkin diambil?
                                    </b-form-checkbox>
                                    <template v-if="row.arancka_hal_kritis == 'Y'">
                                      <b-form-textarea @input="inputData('hal_kritis_text')" id="hal_kritis_text" v-model="row.arancka_hal_kritis_text" class="form-control" rows="2"></b-form-textarea>
                                      <VValidate name="Hal Kritis" v-model="row.arancka_hal_kritis_text"
                                        :rules="{required: 1}" />
                                    </template>
                                          
                                    <span class="text-warning d-none" id="hal_kritis-typing">User Lain Sedang Mengisi ...</span>
                                    
                                    <span class="text-warning d-none" id="hal_kritis_text-typing">User Lain Sedang Mengisi ...</span>
                                  </div>
                                  
                                  <div class="col-md-4">
                                    <b-form-checkbox
                                        @change="inputData('estimasi_operasi')" id="estimasi_operasi" 
                                        v-model="row.arancka_estimasi_operasi"
                                        name="arancka_estimasi_operasi"
                                        value="Y"
                                        unchecked-value="N"
                                    >
                                    Berapa estimasi lama operasi?
                                    </b-form-checkbox>
                                    <template v-if="row.arancka_estimasi_operasi == 'Y'">
                                      <b-form-textarea @input="inputData('estimasi_operasi_text')" id="estimasi_operasi_text"  v-model="row.arancka_estimasi_operasi_text" class="form-control" rows="2"></b-form-textarea>
                                      <VValidate name="Estimasi Operasi" v-model="row.arancka_estimasi_operasi_text"
                                        :rules="{required: 1}" />
                                    </template>
                                    
                                    <span class="text-warning d-none" id="estimasi_operasi-typing">User Lain Sedang Mengisi ...</span>
                                    
                                    <span class="text-warning d-none" id="estimasi_operasi_text-typing">User Lain Sedang Mengisi ...</span>
                                  </div>

                                  <div class="col-md-4">
                                    <b-form-checkbox
                                        @change="inputData('antisipasi_kehilangan_darah')" id="antisipasi_kehilangan_darah"
                                        v-model="row.arancka_antisipasi_kehilangan_darah"
                                        name="arancka_antisipasi_kehilangan_darah"
                                        value="Y"
                                        unchecked-value="N"
                                    >
                                    Antisipasi kehilangan darah yang dipersiapkan?
                                    </b-form-checkbox>
                                    <template v-if="row.arancka_antisipasi_kehilangan_darah == 'Y'">
                                      <b-form-textarea 
                                      @input="inputData('antisipasi_kehilangan_darah_text')" id="antisipasi_kehilangan_darah_text"
                                      v-model="row.arancka_antisipasi_kehilangan_darah_text" class="form-control" rows="2"></b-form-textarea>
                                      <VValidate name="Antisipasi kehilangan darah" v-model="row.arancka_antisipasi_kehilangan_darah_text"
                                        :rules="{required: 1}" />
                                    </template>
                                    
                                    <span class="text-warning d-none" id="antisipasi_kehilangan_darah-typing">User Lain Sedang Mengisi ...</span>
                                    
                                    <span class="text-warning d-none" id="antisipasi_kehilangan_darah_text-typing">User Lain Sedang Mengisi ...</span>
                                  </div>
                                </div>
                              </div>

                              <div class="form-group">
                                <label class="font-weight-semibold">Tim Anestesi</label>
                                <div class="col-md-4">
                                  <b-form-checkbox
                                      @change="inputData('hal_penting')" id="hal_penting"
                                      v-model="row.arancka_hal_penting"
                                      name="arancka_hal_penting"
                                      value="Y"
                                      unchecked-value="N"
                                  >
                                  Adakah terdapat hal penting mengenai pasien yang perlu diperhatikan?
                                  </b-form-checkbox>
                                  <template v-if="row.arancka_hal_penting == 'Y'">
                                    <b-form-textarea @input="inputData('hal_penting_text')" id="hal_penting_text" v-model="row.arancka_hal_penting_text" class="form-control" rows="2"></b-form-textarea>
                                    <VValidate name="Tim Anestesi" v-model="row.arancka_hal_penting_text"
                                      :rules="{required: 1}" />
                                  </template>
                                  
                                  <span class="text-warning d-none" id="hal_penting-typing">User Lain Sedang Mengisi ...</span>
                                    
                                  <span class="text-warning d-none" id="hal_penting_text-typing">User Lain Sedang Mengisi ...</span>
                                </div>
                              </div>

                              <div class="form-group">
                                <label class="font-weight-semibold">Tim Keperawatan</label>
                                <div class="row">
                                  <div class="col-md-4">
                                    <b-form-checkbox
                                        @change="inputData('sterilitas')" id="sterilitas"
                                        v-model="row.arancka_sterilitas"
                                        name="arancka_sterilitas"
                                        value="Y"
                                        unchecked-value="N"
                                    >
                                    Sudahkah sterilitas dipastikan (termasuk hasil indikator)?
                                    </b-form-checkbox>
                                    <template v-if="row.arancka_sterilitas == 'Y'">
                                      <b-form-textarea @input="inputData('sterilitas_text')"  id="sterilitas_text" v-model="row.arancka_sterilitas_text" class="form-control" rows="2"></b-form-textarea>
                                      <VValidate name="Sterilitas" v-model="row.arancka_sterilitas_text"
                                        :rules="{required: 1}" />
                                    </template>
          
                                    <span class="text-warning d-none" id="sterilitas-typing">User Lain Sedang Mengisi ...</span>
                                      
                                    <span class="text-warning d-none" id="sterilitas_text-typing">User Lain Sedang Mengisi ...</span>
                                  </div>
                                  <div class="col-md-4">
                                    <b-form-checkbox
                                        @change="inputData('masalah')" id="masalah"
                                        v-model="row.arancka_masalah"
                                        name="arancka_masalah"
                                        value="Y"
                                        unchecked-value="N"
                                    >
                                    Adakah masalah atau perhatian khusus mengenai peralatan?
                                    </b-form-checkbox>
                                    <template v-if="row.arancka_masalah == 'Y'">
                                      <b-form-textarea @input="inputData('masalah_text')"  id="masalah_text" v-model="row.arancka_masalah_text" class="form-control" rows="2"></b-form-textarea>
                                      <VValidate name="Masalah" v-model="row.arancka_masalah_text"
                                        :rules="{required: 1}" />
                                    </template>
                                    
                                    <span class="text-warning d-none" id="masalah-typing">User Lain Sedang Mengisi ...</span>
                                      
                                    <span class="text-warning d-none" id="masalah_text-typing">User Lain Sedang Mengisi ...</span>

                                  </div>
                                  <div class="col-md-4">
                                    <b-form-checkbox
                                        @change="inputData('implant')" id="implant"
                                        v-model="row.arancka_implant"
                                        name="arancka_implant"
                                        value="Y"
                                        unchecked-value="N"
                                    >
                                    Apakah implant sudah tersedia ?
                                    </b-form-checkbox>
                                    <template v-if="row.arancka_implant == 'Y'">
                                      <b-form-textarea @input="inputData('implant_text')"  
                                      id="implant_text" v-model="row.arancka_implant_text" class="form-control" rows="2"></b-form-textarea>
                                      <VValidate name="Implant" v-model="row.arancka_implant_text"
                                        :rules="{required: 1}" />
                                    </template>
                                    
                                    <span class="text-warning d-none" id="implant-typing">User Lain Sedang Mengisi ...</span>
                                      
                                    <span class="text-warning d-none" id="implant_text-typing">User Lain Sedang Mengisi ...</span>
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td>

                              <div class="form-group mt-2">
                                <label> Hasil pemeriksaan imaging penting ditampilkan?</label>
                                <b-form-radio-group
                                  @input="inputData('imaging')" id="imaging"
                                  :options="Config.mr.yesNoOptV2"
                                  v-model="row.arancka_imaging"
                                />
                                
                                <span class="text-warning d-none" id="imaging-typing">User Lain Sedang Mengisi ...</span>
                                
                                <VValidate name="Hasil pemeriksaan imaging penting ditampilkan?" v-model="row.arancka_imaging"
                                :rules="{required:1}" />
                                
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                </div>
              </div>
            </div>
            <div class="card-footer">
              <div class="text-right">
                <button type="button" @click="updateTime('arano_last_time_out', 'Time Out')" class="btn btn-primary">Simpan <i class="icon-paperplane ml-2"></i></button>
                <br/><small v-if="row.arano_last_time_out">Last Update : {{row.arano_last_time_out | moment("DD MMMM YYYY, HH:mm")}}</small>
              </div>
            </div>
            <!-- -->

            <!-- -->
            <div class="card-body">
              <div class="row">
                <div class="col-md-12">
                  <div class="wrap_width_table">
                    <div class="table-responsive">
                      <table class="table  table-sm  table-bordered">
                        <thead>
                          <tr>
                            <th>Sign Out</th>
                          </tr>
                          <tr>
                            <th class="table-info">Sebelum meninggalkan ruang operasi<br>(dengan perawat, dokter anesthesi
                              dan dokter bedah )</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="form-group col-md-6 col-lg-4">
                                    <label for="kenyamananNyeri">Tanggal <span class="text-danger">*</span></label>
                                    <div class="input-group">
                                    <datepicker @input="inputData('so_date')" id="so_date" input-class="form-control transparent h-38" placeholder="Tanggal"
                                        class="my-datepicker" calendar-class="my-datepicker_calendar"
                                        v-model="row.arancka_so_date">
                                    </datepicker>
                                    <vue-timepicker manual-input @input="inputData('so_jam')" id="so_jam" format="HH:mm" placeholder="Jam" input-class="form-control h-38" v-model="row.arancka_so_jam">
                                    </vue-timepicker>
                                    <div class="input-group-append calendar-group">
                                        <span class="input-group-text" id="basic-addon2"><i
                                            class="icon-calendar"></i></span>
                                    </div>
                                    </div>
                                        
                                    <span class="text-warning d-none" id="so_date-typing">User Lain Sedang Mengisi ...</span>

                                    <span class="text-warning d-none" id="so_jam-typing">User Lain Sedang Mengisi ...</span>
                                    
                                    <VValidate 
                                        :name="'Tanggal Sign Out'" 
                                        v-model="row.arancka_so_date" 
                                        :rules="{required : 1}"
                                        class="d-block"
                                    />
                                    <VValidate :name="'Jam Sign Out'" v-model="row.arancka_so_jam"
                                        :rules="{required: 1}" />
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div>Tim Keperawatan secara lisan mengkonfirmasi di hadapan TIM:</div>
                              <div class="d-flex">
                                <b-form-checkbox
                                  @change="inputData('so_nama_tindakan')" id="so_nama_tindakan"
                                  v-model="row.arancka_so_nama_tindakan"
                                  name="arancka_so_nama_tindakan"
                                  value="Y"
                                  unchecked-value="N"
                                >
                                  Nama prosedur tindakan
                                </b-form-checkbox>

                                <b-form-checkbox
                                  class="ml-3"
                                  @change="inputData('so_kelengkapan')" id="so_kelengkapan"
                                  v-model="row.arancka_so_kelengkapan"
                                  name="arancka_so_kelengkapan"
                                  value="Y"
                                  unchecked-value="N"
                                >
                                  Kelengkapan hitungan instrumen, spons dan jarum sudah sesuai
                                </b-form-checkbox>

                            
                                <span class="text-warning d-none" id="so_nama_tindakan-typing">User Lain Sedang Mengisi ...</span>

                                <span class="text-warning d-none" id="so_kelengkapan-typing">User Lain Sedang Mengisi ...</span>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <table class="table table-input  table-sm  table-bordered">
                                <thead class="text-center">
                                  <tr>
                                    <th style="width:250px;">Jenis Item</th>
                                    <th>Hitungan Pertama</th>
                                    <th colspan="3">Tambahan Selama Operasi</th>
                                    <th>Jumlah</th>
                                    <th>Hitungan Kedua</th>
                                    <th>Hitungan Terakhir</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr v-for="(v,k) in (row.arancka_so_data||[])" :key="k">
                                    <td>{{v.jenis}}</td>
                                    <td class="input-cell"><input 
                                    @input="inputData('so_data')" v-model="row.arancka_so_data[k]['hitungan_pertama']" type="text" class="form-control" placeholder=""></td>
                                    <td class="input-cell"><input 
                                    @input="inputData('so_data')" v-model="row.arancka_so_data[k]['tambahan1']" type="text" class="form-control" placeholder=""></td>
                                    <td class="input-cell"><input 
                                    @input="inputData('so_data')" v-model="row.arancka_so_data[k]['tambahan2']" type="text" class="form-control" placeholder=""></td>
                                    <td class="input-cell"><input 
                                    @input="inputData('so_data')" v-model="row.arancka_so_data[k]['tambahan3']" type="text" class="form-control" placeholder=""></td>
                                    <td class="input-cell"><input 
                                    @input="inputData('so_data')" v-model="row.arancka_so_data[k]['jumlah']" type="text" class="form-control" placeholder=""></td>
                                    <td class="input-cell"><input 
                                    @input="inputData('so_data')" v-model="row.arancka_so_data[k]['hitungan_kedua']" type="text" class="form-control" placeholder=""></td>
                                    <td class="input-cell"><input 
                                    @input="inputData('so_data')" v-model="row.arancka_so_data[k]['hitungan_terakhir']" type="text" class="form-control" placeholder=""></td>
                                  </tr>
                                </tbody>
                              </table>

                              <div class="my-2">
                                <b-form-checkbox
                                  @change="inputData('so_label_spesimen')" id="so_label_spesimen"
                                  v-model="row.arancka_so_label_spesimen"
                                  name="arancka_so_label_spesimen"
                                  value="Y"
                                  unchecked-value="N"
                                >
                                  Label spesimen (minimal terdapat asal jaringan, nama pasien, tanggal lahir dan nomer RM)
                                </b-form-checkbox>

                                <span class="text-warning d-none" id="so_label_spesimen-typing">User Lain Sedang Mengisi ...</span>
                              </div>

                              <div class="my-2">
                                <b-form-checkbox
                                  @change="inputData('so_terdapat_permasalahan')" id="so_terdapat_permasalahan"
                                  v-model="row.arancka_so_terdapat_permasalahan"
                                  name="arancka_so_terdapat_permasalahan"
                                  value="Y"
                                  unchecked-value="N"
                                >
                                  Apakah terdapat permasalahan peralatan yang perlu disampaikan,
                                </b-form-checkbox>
                                
                                <div class="ml-2 mt-2" v-if="row.arancka_so_terdapat_permasalahan == 'Y'">
                                  <b-form-input @input="inputData('so_terdapat_permasalahan_text')" id="so_terdapat_permasalahan_text" v-model="row.arancka_so_terdapat_permasalahan_text" type="text" class="form-control" placeholder="" />
                                  <VValidate 
                                    name="Terdapat Permasalahan" 
                                    v-model="row.arancka_so_terdapat_permasalahan_text" 
                                    :rules="{required:1, min:3, max:128}"
                                  />
                                </div>
                                
                                <span class="text-warning d-none" id="so_terdapat_permasalahan-typing">User Lain Sedang Mengisi ...</span>
                                
                                <span class="text-warning d-none" id="so_terdapat_permasalahan_text-typing">User Lain Sedang Mengisi ...</span>
                              </div>

                              <div class="my-2">
                                <b-form-checkbox
                                  @change="inputData('so_pemulihan_masalah')" id="so_pemulihan_masalah"
                                  v-model="row.arancka_so_pemulihan_masalah"
                                  name="arancka_so_pemulihan_masalah"
                                  value="Y"
                                  unchecked-value="N"
                                >
                                  Kepada Operator, Dokter Anestesi dan Tim Keperawatan, Apakah terdapat pesan khusus untuk pemulihan pasien?
                                </b-form-checkbox>
                                
                                <div class="ml-2 mt-2" v-if="row.arancka_so_pemulihan_masalah == 'Y'">
                                  <b-form-input @input="inputData('so_pemulihan_masalah_text')" id="so_pemulihan_masalah_text" v-model="row.arancka_so_pemulihan_masalah_text" type="text" class="form-control" placeholder="" />
                                  <VValidate 
                                    name="Pemulihan Masalah" 
                                    v-model="row.arancka_so_pemulihan_masalah_text" 
                                    :rules="{required:1, min:3, max:128}"
                                  />
                                </div>
                                
                                <span class="text-warning d-none" id="so_pemulihan_masalah-typing">User Lain Sedang Mengisi ...</span>
                                
                                <span class="text-warning d-none" id="so_pemulihan_masalah_text-typing">User Lain Sedang Mengisi ...</span>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                </div>
              </div>
            </div>
            <div class="card-footer">
              <div class="text-right">
                <button type="button" @click="updateTime('arano_last_sign_out', 'Sign Out')" class="btn btn-primary">Simpan <i class="icon-paperplane ml-2"></i></button>
                <br/><small v-if="row.arano_last_sign_out">Last Update : {{row.arano_last_sign_out | moment("DD MMMM YYYY, HH:mm")}}</small>
              </div>
            </div>
            <!-- -->



            <div class="card-footer">
              <div class="text-right">
                <button type="button" @click="back()" class="btn btn-light mr-3">Back</button>
                <button type="submit" class="btn btn-primary">Submit <i class="icon-paperplane ml-2"></i></button>
              </div>
            </div>
          </div>
        </b-form>
      </validation-observer>
    </div>
  </div>
</template>


<script>
import GlobalVue from '@/libs/Global.vue'
import $ from 'jquery'
const _ = global._
import Gen from '@/libs/Gen.js'
import PatientInfo from '@/components/Ranap/PatientInfo.vue'
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'
import Datepicker from 'vuejs-datepicker'


export default {
  extends: GlobalVue,
  data(){
    return {
      rowReg: {},
      menuPage: 'RanapCeklistKeselamatanAnestesi',
      
      patientData: {},
      loading: {
          patientInfo: false,
      },

    }
  },
  components: {
    PatientInfo,VueTimepicker,Datepicker
  },
  mounted() {
    this.apiGet() 

    this.getPatientInfo()

    setTimeout(()=>{
      if(this.$refs['ttdDokterOperator']){
        this.$refs['ttdDokterOperator'].fromDataURL(this.row.arancka_ttd_dokter_operator)
      } 
      if(this.$refs['ttdDokterAnestesi']){
        this.$refs['ttdDokterAnestesi'].fromDataURL(this.row.arancka_ttd_dokter_anestesi)
      } 
      if(this.$refs['ttdPerawatSirkuler']){
        this.$refs['ttdPerawatSirkuler'].fromDataURL(this.row.arancka_ttd_perawat_sirkuler)
      } 

      if(this.$refs['ttdTODokterOperator']){
        this.$refs['ttdTODokterOperator'].fromDataURL(this.row.arancka_to_ttd_dokter_operator)
      } 
      if(this.$refs['ttdTODokterAnestesi']){
        this.$refs['ttdTODokterAnestesi'].fromDataURL(this.row.arancka_to_ttd_dokter_anestesi)
      } 
      if(this.$refs['ttdTOPerawatSirkuler']){
        this.$refs['ttdTOPerawatSirkuler'].fromDataURL(this.row.arancka_to_ttd_perawat_sirkuler)
      } 
      
      if(this.$refs['ttdSODokterOperator']){
        this.$refs['ttdSODokterOperator'].fromDataURL(this.row.arancka_so_ttd_dokter_operator)
      } 
      if(this.$refs['ttdSODokterAnestesi']){
        this.$refs['ttdSODokterAnestesi'].fromDataURL(this.row.arancka_so_ttd_dokter_anestesi)
      } 
      if(this.$refs['ttdSOPerawatSirkuler']){
        this.$refs['ttdSOPerawatSirkuler'].fromDataURL(this.row.arancka_so_ttd_perawat_sirkuler)
      } 
    },2000)

    this.sockets.subscribe('show_checklist_keselamatan', function(data) {
      if(data.arano_id == this.$route.params.pageSlug){
        console.log(data.users)
        console.log(this.user.id)
        if(data.users != this.user.id){
          let pattern = [
            { element: 'date', field: 'arancka_date' },
            { element: 'jam', field: 'arancka_jam' },
            { element: 'to_date', field: 'arancka_to_date' },
            { element: 'to_jam', field: 'arancka_to_jam' },
            { element: 'so_date', field: 'arancka_so_date' },
            { element: 'so_jam', field: 'arancka_so_jam' },
            { element: 'konfirmasi_identitas', field: 'arancka_konfirmasi_identitas' },
            { element: 'lokasi_marking', field: 'arancka_lokasi_marking' },
            { element: 'mesin_dicek', field: 'arancka_mesin_dicek' },
            { element: 'pulse_oximeter', field: 'arancka_pulse_oximeter' },
            { element: 'riwayat_alergi', field: 'arancka_riwayat_alergi' },
            { element: 'resiko_kesulitan', field: 'arancka_resiko_kesulitan' },
            { element: 'kehilangan_darah', field: 'arancka_kehilangan_darah' },
            { element: 'nama_dokter_operator', field: 'arancka_nama_dokter_operator' },
            { element: 'ttd_dokter_operator', field: 'arancka_ttd_dokter_operator' },
            { element: 'nama_dokter_anestesi', field: 'arancka_nama_dokter_anestesi' },
            { element: 'ttd_dokter_anestesi', field: 'arancka_ttd_dokter_anestesi' },
            { element: 'nama_perawat_sirkuler', field: 'arancka_nama_perawat_sirkuler' },
            { element: 'ttd_perawat_sirkuler', field: 'arancka_ttd_perawat_sirkuler' },
            // Add more elements and fields as needed
            { element: 'seluruh_anggota_menyebutkan_nama', field: 'arancka_seluruh_anggota_menyebutkan_nama' },
            { element: 'pastikan_nama_pasien', field: 'arancka_pastikan_nama_pasien' },
            { element: 'antibiotik', field: 'arancka_antibiotik' },
            { element: 'antibiotik_diterapkan', field: 'arancka_antibiotik_diterapkan' },
            { element: 'hal_kritis', field: 'arancka_hal_kritis' },
            { element: 'hal_kritis_text', field: 'arancka_hal_kritis_text' },
            { element: 'estimasi_operasi', field: 'arancka_estimasi_operasi' },
            { element: 'estimasi_operasi_text', field: 'arancka_estimasi_operasi_text' },
            { element: 'antisipasi_kehilangan_darah', field: 'arancka_antisipasi_kehilangan_darah' },
            { element: 'antisipasi_kehilangan_darah_text', field: 'arancka_antisipasi_kehilangan_darah_text' },
            { element: 'hal_penting', field: 'arancka_hal_penting' },
            { element: 'hal_penting_text', field: 'arancka_hal_penting_text' },
            { element: 'sterilitas', field: 'arancka_sterilitas' },
            { element: 'sterilitas_text', field: 'arancka_sterilitas_text' },
            { element: 'masalah', field: 'arancka_masalah' },
            { element: 'masalah_text', field: 'arancka_masalah_text' },
            { element: 'implant', field: 'arancka_implant' },
            { element: 'implant_text', field: 'arancka_implant_text' },
            { element: 'imaging', field: 'arancka_imaging' },
            { element: 'to_nama_dokter_operator', field: 'arancka_to_nama_dokter_operator' },
            { element: 'to_ttd_dokter_operator', field: 'arancka_to_ttd_dokter_operator' },
            { element: 'to_nama_dokter_anestesi', field: 'arancka_to_nama_dokter_anestesi' },
            { element: 'to_ttd_dokter_anestesi', field: 'arancka_to_ttd_dokter_anestesi' },
            { element: 'to_nama_perawat_sirkuler', field: 'arancka_to_nama_perawat_sirkuler' },
            { element: 'to_ttd_perawat_sirkuler', field: 'arancka_to_ttd_perawat_sirkuler' },
            { element: 'so_nama_tindakan', field: 'arancka_so_nama_tindakan' },
            { element: 'so_kelengkapan', field: 'arancka_so_kelengkapan' },
            { element: 'so_data', field: 'arancka_so_data' },
            { element: 'so_label_spesimen', field: 'arancka_so_label_spesimen' },
            { element: 'so_terdapat_permasalahan', field: 'arancka_so_terdapat_permasalahan' },
            { element: 'so_terdapat_permasalahan_text', field: 'arancka_so_terdapat_permasalahan_text' },
            { element: 'so_pemulihan_masalah', field: 'arancka_so_pemulihan_masalah' },
            { element: 'so_pemulihan_masalah_text', field: 'arancka_so_pemulihan_masalah_text' },
            { element: 'so_nama_dokter_operator', field: 'arancka_so_nama_dokter_operator' },
            { element: 'so_ttd_dokter_operator', field: 'arancka_so_ttd_dokter_operator' },
            { element: 'so_nama_dokter_anestesi', field: 'arancka_so_nama_dokter_anestesi' },
            { element: 'so_ttd_dokter_anestesi', field: 'arancka_so_ttd_dokter_anestesi' },
            { element: 'so_nama_perawat_sirkuler', field: 'arancka_so_nama_perawat_sirkuler' },
            { element: 'so_ttd_perawat_sirkuler', field: 'arancka_so_ttd_perawat_sirkuler' },
            // Add more elements and fields as needed
          ]

          for(let i = 0; i < (pattern||[]).length; i++){
            if (data.element === pattern[i]['element']) {
              this.row[pattern[i]['field']] = data[pattern[i]['field']]
            }
            // if(data.element.indexOf('ttd') != -1){
            //   this.refreshTTD()
            // }
          }

          let elem = document.getElementById(data.element)
          if(elem){
              let elemtype = document.getElementById(data.element+"-typing")
              elem.disabled = true
              elemtype.classList.remove('d-none')        

              setTimeout(()=>{
                  elem.disabled = false      
                  elemtype.classList.add('d-none')              
              },1000)
          }
        }
      }
    })
    
  },
  methods: {
    refreshTTD(){
      setTimeout(()=>{
        if(this.$refs['ttdDokterOperator']){
          this.$refs['ttdDokterOperator'].clearSignature()
          this.$refs['ttdDokterOperator'].fromDataURL(this.row.arancka_ttd_dokter_operator)
        } 
        if(this.$refs['ttdDokterAnestesi']){
          this.$refs['ttdDokterAnestesi'].clearSignature()
          this.$refs['ttdDokterAnestesi'].fromDataURL(this.row.arancka_ttd_dokter_anestesi)
        } 
        if(this.$refs['ttdPerawatSirkuler']){
          this.$refs['ttdPerawatSirkuler'].clearSignature()
          this.$refs['ttdPerawatSirkuler'].fromDataURL(this.row.arancka_ttd_perawat_sirkuler)
        } 

        if(this.$refs['ttdTODokterOperator']){
          this.$refs['ttdTODokterOperator'].clearSignature()
          this.$refs['ttdTODokterOperator'].fromDataURL(this.row.arancka_to_ttd_dokter_operator)
        } 
        if(this.$refs['ttdTODokterAnestesi']){
          this.$refs['ttdTODokterAnestesi'].clearSignature()
          this.$refs['ttdTODokterAnestesi'].fromDataURL(this.row.arancka_to_ttd_dokter_anestesi)
        } 
        if(this.$refs['ttdTOPerawatSirkuler']){
          this.$refs['ttdTOPerawatSirkuler'].clearSignature()
          this.$refs['ttdTOPerawatSirkuler'].fromDataURL(this.row.arancka_to_ttd_perawat_sirkuler)
        } 
        
        if(this.$refs['ttdSODokterOperator']){
          this.$refs['ttdSODokterOperator'].clearSignature()
          this.$refs['ttdSODokterOperator'].fromDataURL(this.row.arancka_so_ttd_dokter_operator)
        } 
        if(this.$refs['ttdSODokterAnestesi']){
          this.$refs['ttdSODokterAnestesi'].clearSignature()
          this.$refs['ttdSODokterAnestesi'].fromDataURL(this.row.arancka_so_ttd_dokter_anestesi)
        } 
        if(this.$refs['ttdSOPerawatSirkuler']){
          this.$refs['ttdSOPerawatSirkuler'].clearSignature()
          this.$refs['ttdSOPerawatSirkuler'].fromDataURL(this.row.arancka_so_ttd_perawat_sirkuler)
        } 
      },500)
    },
    updateTime(field, wording){
      this.$swal({
          icon: 'warning',
          title: 'Apakah Anda Yakin akan memperbarui data '+wording+'?',
          showCancelButton: true,
          confirmButtonText: 'Ya',
          cancelButtonText: 'Tidak, kembali'
      }).then(result => {
        if(result.value){
          let data = this.row
          data.id = this.row.arano_id
          data.field = field
          data.type = 'auto-save'
          
          this.loadingOverlay = true
            Gen.apiRest(
            "/do/" + this.menuPage, {
                data: data
            },
            "POST"
            ).then(res => {
              this.loadingOverlay = false
              let resp = res.data
              resp.statusType = 200
              this.$swal({
                  title: resp.message,
                  icon: resp.status,
                  confirmButtonText: 'Ok',
                  allowOutsideClick: false,
                  allowEscapeKey: false
              })
              this.apiGet()
              
              data.type = 'update-time'
              Gen.apiRest(
                "/do/"+this.menuPage,
                {data:data}, 
                "POST"
              )

            }).catch(err => {
              this.loadingOverlay = false
              if (err) {
                err.statusType = err.status
                err.status = "error"
                err.title = err.response?.data?.title
                err.message = err.response?.data?.message
                err.messageError = err.message
              }
              this.doSetAlertForm(err)
            })
        }
      })
    },
    undoTTD(refs,field,fieldKet){
      this.$refs[refs].clearSignature()
      this.row[field] = null
      setTimeout(()=>{
        this.autoSave(this.row,true)
        this.inputData(fieldKet)
      },100)
    },
    output(refs,field,fieldKet) {
      let { isEmpty, data } = this.$refs[refs].saveSignature()
      if(!isEmpty){
        this.row[field] = data
        setTimeout(()=>{
          this.autoSave(this.row,true)
          this.inputData(fieldKet)      
        },100)
      }
    },

    back() {
      this.$router.back()
    },

    toValidate(val){
      return {...val}
    },

    apiGet(params = {}, page = 1){
        if(!this.pageSlug || !this.$route.query.regId){
            this.$router.push({name : 'Dashboard'}).catch(()=>{})
        }

        if(this.pageSlug || this.$route.name=='Dashboard') this.loadingOverlay = true
        this.data.data = false
        let paramsQuery = Object.keys(this.$route.query).length ? this.apiParams : this.$route.query
        let url = this.menuPage+'/'+this.$route.params.pageSlug
        
        Gen.apiRest(
            "/get/"+url, 
            {
            params: Object.assign({page: page}, paramsQuery, params.query||{})
            }
        ).then(res=>{
            this.loadingOverlay = false
            _.forEach(res.data, (v,k)=>{
                this.$set(this, k, v)
            })
            if(!this.isFound){
                this.$router.push({name : 'Dashboard'}).catch(()=>{})
            }
        }).catch(()=>{
            this.loadingOverlay = false
        })

        // master
        if(this.$route.query.regId){
            Gen.apiRest(
                "/get/"+url+'?master=1', 
                {
                params: Object.assign({page: page}, paramsQuery, params.query||{})
                }
            ).then(res=>{
                console.log(res.data)
                _.forEach(res.data, (v,k)=>{
                    this.$set(this, k, v)
                })
            })
        }
        
    },
    
    getPatientInfo() {
      this.$set(this.loading, 'patientInfo', true)
      Gen.apiRest(
      "/do/" + 'RanapPasienPerawatan', {
          data: {
            type: "get-data-pasien",
            id_registrasi: this.$route.query.regId
          }
      },
      "POST"
      ).then(resp => {
      this.$set(this, 'patientData', resp.data.data)

      this.$set(this.loading, 'patientInfo', false)
      })
    },

    getConfigDynamic(master,value){
        let text = ''
        if(value){
            let index = (master||[]).findIndex(x => x.value == value)
            if(index !== -1){
                text = master[index]['text']
            }
        }
        return text
    },
    
    autoSave: _.debounce(function (data) {
        data.type = 'auto-save'
        if(!data.isEdit){
            Gen.apiRest(
                "/do/"+this.menuPage,
                {data:data}, 
                "POST"
            )
        }
    },1000),

    
    doSubmit(){
      this.$refs['VForm'].validate().then(success => {
        if (!success) {
          return this.$swal({
              icon: 'error',
              title: 'Data Tidak Lengkap, Mohon Lengkapi Data Terlebih Dahulu'
          }).then(result => {
              if (result.value) {
                  setTimeout(() => {
                      let inv = []
                      let el = document.querySelectorAll(".label_error:not([style='display: none;'])")
                      console.log(el)
                      for (let i = 0; i < (el || []).length; i++) {
                          if (el[i].style.display !== 'none') {
                          inv.push(el[i].id)
                          }
                      }
                                  
                      if (inv.length) document.getElementById(inv[0]).scrollIntoView({
                          behavior: 'smooth',
                          block: 'center'
                      })
                  }, 500)
              }
          })
        }
        if (success) {
          this.$swal({
              icon: 'warning',
              title: 'Apakah Anda Yakin akan menyimpan data ini?',
              showCancelButton: true,
              confirmButtonText: 'Ya',
              cancelButtonText: 'Tidak, kembali'
          }).then(result => {
            if (result.value) {
              let data = this.row
              data.type = 'submit-data'
              
              this.loadingOverlay = true
              Gen.apiRest(
              "/do/" + this.menuPage, {
                  data: data
              },
              "POST"
              ).then(res => {
                this.loadingOverlay = false
                
                let resp = res.data
                resp.statusType = 200
                this.$swal({
                    title: resp.message,
                    icon: resp.status,
                    confirmButtonText: 'Ok',
                    allowOutsideClick: false,
                    allowEscapeKey: false
                }).then(result => {
                    this.$router.push({ name: 'RanapOperasiPerawatBedah'}).catch(()=>{})   
                })
              }).catch(err => {
                this.loadingOverlay = false
                if (err) {
                  err.statusType = err.status
                  err.status = "error"
                  err.title = err.response?.data?.title
                  err.message = err.response?.data?.message
                  err.messageError = err.message
                }
                this.doSetAlertForm(err)
              })
            }
          })
        }
      })
    },

    
    inputData: _.debounce(function (element) {
      let data = this.row
      data.to = this.$route.params.pageSlug
      data.users = this.user.id
      data.element = element
      this.$socket.emit('checklist_keselamatan',data)
    },1000),
  },
  watch:{
    row: {
        handler(v) {
            this.autoSave(v)
        },
        deep: true
    }
  }
}
</script>